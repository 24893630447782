// Generated by Framer (1a71db7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-iSwAF"

const variantClassNames = {xbIQR0Ao8: "framer-v-2iivjm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({colorCode, documentationType, height, id, width, ...props}) => { return {...props, aYBuzot6H: colorCode ?? props.aYBuzot6H ?? "var(--token-63715219-0a10-4720-bbb1-f736540ba7b5, rgb(204, 94, 84))", t8ILo4KHA: documentationType ?? props.t8ILo4KHA ?? "Context"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;colorCode?: string;documentationType?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, aYBuzot6H, t8ILo4KHA, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "xbIQR0Ao8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-2iivjm", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"xbIQR0Ao8"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1jlf9no"} layoutDependency={layoutDependency} layoutId={"JhODyj54e"} style={{backgroundColor: aYBuzot6H, borderBottomLeftRadius: 58, borderBottomRightRadius: 58, borderTopLeftRadius: 58, borderTopRightRadius: 58}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlI7SW50ZXJEaXNwbGF5LUJvbGQ=", "--framer-font-family": "\"Inter Display\", \"Inter Display Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "700"}}>text</motion.p></React.Fragment>} className={"framer-127klq8"} fonts={["FR;InterDisplay-Bold"]} layoutDependency={layoutDependency} layoutId={"xt6B4wkIj"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={t8ILo4KHA} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iSwAF.framer-17ph8fl, .framer-iSwAF .framer-17ph8fl { display: block; }", ".framer-iSwAF.framer-2iivjm { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-iSwAF .framer-1jlf9no { flex: none; height: 22px; overflow: hidden; position: relative; width: 22px; will-change: var(--framer-will-change-override, transform); }", ".framer-iSwAF .framer-127klq8 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-iSwAF.framer-2iivjm { gap: 0px; } .framer-iSwAF.framer-2iivjm > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-iSwAF.framer-2iivjm > :first-child { margin-left: 0px; } .framer-iSwAF.framer-2iivjm > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 97
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"aYBuzot6H":"colorCode","t8ILo4KHA":"documentationType"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerslDaX1srU: React.ComponentType<Props> = withCSS(Component, css, "framer-iSwAF") as typeof Component;
export default FramerslDaX1srU;

FramerslDaX1srU.displayName = "color code";

FramerslDaX1srU.defaultProps = {height: 22, width: 97};

addPropertyControls(FramerslDaX1srU, {aYBuzot6H: {defaultValue: "var(--token-63715219-0a10-4720-bbb1-f736540ba7b5, rgb(204, 94, 84)) /* {\"name\":\"Timeline Red\"} */", title: "color code", type: ControlType.Color}, t8ILo4KHA: {defaultValue: "Context", displayTextArea: false, title: "documentation type", type: ControlType.String}} as any)

addFonts(FramerslDaX1srU, [{explicitInter: true, fonts: [{family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/I11LrmuBDQZweplJ62KkVsklU5Y.woff2", weight: "700"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/UjFZPDy3qGuDktQM4q9CxhKfIa8.woff2", weight: "700"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/8exwVHJy2DhJ4N5prYlVMrEKmQ.woff2", weight: "700"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/UTeedEK21hO5jDxEUldzdScUqpg.woff2", weight: "700"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/Ig8B8nzy11hzIWEIYnkg91sofjo.woff2", weight: "700"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/n9CXKI3tsmCPeC6MCT9NziShSuQ.woff2", weight: "700"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/qctQFoJqJ9aIbRSIp0AhCQpFxn8.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})